export const MHS_TIERS = [
  {
    label: 'member',
    amount: 500,
  },
  {
    label: 'partner',
    amount: 2500,
  },
  {
    label: 'advocate',
    amount: 5000,
  },
  {
    label: 'defender',
    amount: 10000,
  },
  {
    label: 'disruptor',
    amount: 25000,
  },
  {
    label: 'champion',
    amount: 50000,
  },
];
